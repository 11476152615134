<script>
import CalendarSVG from '@/components/CalendarSVG.vue';
export default {
  name: 'Upgrade',
  components: {
    CalendarSVG,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    jobType: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      freelanceCredits: '0',
      permanentCredits: '0',

      form: {},

      m_payment_id: '',
      pay_return:
        process.env.VUE_APP_MODE === 'development'
          ? 'https://staging.recruitaguide.co.za/pages/dashboard?payment=true'
          : 'https://business.recruitaguide.co.za/pages/dashboard?payment=true',
      pay_notify:
        process.env.VUE_APP_MODE === 'development'
          ? 'https://us-central1-recreuitaguide-dev.cloudfunctions.net/handlePayment'
          : // 'http://localhost:5001/recreuitaguide-dev/us-central1/handlePayment'
            'https://us-central1-guidenova.cloudfunctions.net/handlePayment',
      pay_action:
        process.env.VUE_APP_MODE === 'development' ? 'https://sandbox.payfast.co.za/eng/process' : 'https://www.payfast.co.za/eng/process',
      pay_merchant_id: process.env.VUE_APP_MODE === 'development' ? '10023512' : '16212907',
      pay_merchant_key: process.env.VUE_APP_MODE === 'development' ? 'nx30hzjunfvce' : 'el30gks73v827',
    };
  },

  computed: {
    purchaseDisabled() {
      return this.freelanceCredits === '0' && this.permanentCredits === '0';
    },
    profile() {
      return this.$store.getters['employerModule/getUser'];
    },
    totalAmount() {
      return this.freelanceCredits * 250 + this.permanentCredits * 850;
    },
    paymentDescription() {
      let description;
      if (parseInt(this.freelanceCredits) > 0 && parseInt(this.permanentCredits) > 0) {
        description = `${this.permanentCredits} x Permanent Job Post Credits and ${this.freelanceCredits} x Freelance Job Post Credits`;
      }
      if (parseInt(this.freelanceCredits) === 0 && parseInt(this.permanentCredits) > 0) {
        description = `${this.permanentCredits} x Permanent Job Post Credits`;
      }
      if (parseInt(this.freelanceCredits) > 0 && parseInt(this.permanentCredits) === 0) {
        description = `${this.freelanceCredits} x Freelance Job Post Credits`;
      }
      return description;
    },
  },
  methods: {
    close() {
      this.$store.dispatch('togglePurchaseCredits', { active: false });
    },
    async submitPayment() {
      if (this.purchaseDisabled) return;
      this.$vs.loading({ type: 'corners' });
      const payment = {
        amount_gross: this.totalAmount.toString() + '.00',
        item_name: 'Job Post Credit',
        item_description: this.paymentDescription,
        freelance_credits: this.freelanceCredits,
        permanent_credits: this.permanentCredits,
        employer_id: this.profile.employerId,
        employer_name: `${this.profile.name} ${this.profile.surname}`,
        payment_status: 'INCOMPLETE',
      };

      await this.$store.dispatch('paymentModule/set', payment);
      this.m_payment_id = this.$store.getters['paymentModule/getId'];
      setTimeout(() => {
        this.$refs.paymentForm.submit();
        this.$vs.loading.close();
      }, 3000);
    },
  },
};
</script>
<template>
  <div v-show="value" class="vac modal-vac modal-popup" :class="{ active: value }">
    <div class="wrapper w-11/12 lg:w-2/3 xl:w-3/5 xxl:w-2/5 xxxl:w-1/3 m-auto">
      <div class="flex flex-wrap">
        <div class="close"> <feather-icon class="icon" icon="XIcon" @click.prevent="close"></feather-icon> </div>
        <vs-row>
          <vs-col class="text-center text-gray-500 w-3/4 sm:w-8/12 m-auto">
            <slot name="header"></slot>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col class="lg:w-10/12 m-0 lg:m-auto w-full">
            <div class="flex-col sm:flex-row justify-evenly plans items-center">
              <vs-card
                v-if="jobType === 'Freelance' || jobType === ''"
                :class="jobType === 'Freelance' ? '' : 'sm:mr-5'"
                class="mr-0 modal-text border-solid border-2 border-gray-200 max-w-xs"
              >
                <div class="flex justify-center items-center flex-col"
                  ><div class="pt-5"><CalendarSVG /> </div>
                  <div class="py-2 px-2 text-center text-rag-green text-xl font-semibold"> Freelance Guide <br />Post </div></div
                >
                <div class="text-center text-gray-500 text-base pb-3">
                  <p>R250 each</p>
                </div>

                <vs-input-number v-model="freelanceCredits" min="0" style="background: #fff !important" />
              </vs-card>

              <vs-card
                v-if="jobType === 'Permanent' || jobType === ''"
                class="mr-0 modal-text border-solid border-2 border-gray-200 max-w-xs"
              >
                <div class="flex justify-center items-center flex-col"
                  ><feather-icon icon="CalendarIcon" class="pt-5" svg-classes="w-6 h-6 text-grey"></feather-icon>
                  <div class="py-2 px-2 text-center text-rag-green text-xl font-semibold"> Permanent Guide <br />Post </div></div
                >

                <div class="text-center text-gray-500 text-base pb-3">
                  <p>R850 each</p>
                </div>

                <vs-input-number v-model="permanentCredits" min="0" class="number_input" />
              </vs-card>
            </div>
          </vs-col>
        </vs-row>

        <vs-row class="flex items-center justify-center flex-col">
          <form ref="paymentForm" :action="pay_action" method="post" @click.prevent="submitPayment()">
            <input type="hidden" name="merchant_id" :value="pay_merchant_id" />
            <input type="hidden" name="merchant_key" :value="pay_merchant_key" />
            <input type="hidden" name="item_name" value="Permanent" />
            <input type="hidden" name="amount" :value="totalAmount.toString() + '.00'" />
            <input type="hidden" name="item_description" :value="paymentDescription" />
            <input type="hidden" name="name_first" :value="profile.name" />
            <input type="hidden" name="name_last" :value="profile.surname" />
            <input type="hidden" name="email_address" :value="profile.email" />
            <!-- <input type="hidden" name="cell_number" :value="profile.telephone" /> -->
            <input type="hidden" name="m_payment_id" :value="m_payment_id" />
            <input type="hidden" name="return_url" :value="pay_return" />
            <input type="hidden" name="cancel_url" :value="pay_return" />
            <input type="hidden" name="notify_url" :value="pay_notify" />
            <input type="hidden" name="custom_str1" :value="profile.employerId" />
            <input type="hidden" name="custom_int1" :value="permanentCredits" />
            <input type="hidden" name="custom_int2" :value="freelanceCredits" />
            <input type="hidden" name="email_confirmation" value="1" />
            <input type="hidden" name="confirmation_address" :value="profile.email" />
            <vs-button :disabled="purchaseDisabled" class="mb-3">Buy Credits (R{{ totalAmount.toLocaleString('en-US') }})</vs-button>
          </form>
          <p class="text-gray-500 pb-3">Powered by <strong>PayFast</strong></p>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
h2 {
  padding-bottom: 25px;
  color: #adca63;
}

.icon,
.modal-text {
  color: #626262 !important;
}

.vac.modal-vac.modal-popup {
  display: flex;
  position: fixed;
  z-index: 1;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  transition: transform 0.4s;
}
.vac.modal-vac.modal-popup .wrapper {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  width: 50%;
  border: 0 !important;
  position: relative;
  border-radius: 8px;
  display: flex;
}

.vac.modal-vac.modal-popup.active {
  opacity: 1;
  z-index: 99999;
}

.plans {
  display: flex;
  font-size: 14px;
  margin: 0 auto;
}

.plan {
  flex: 1;
  margin: 0 20px 20px 0;
  padding: 0 0 50px 0;
  position: relative;
}

.number_input {
  background: #fff !important;
}
</style>
